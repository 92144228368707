<script setup lang="ts">
import { dir } from '@@/bits/current_dir'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import { defineAsyncComponent } from '@@/bits/vue'
import { useAppBarAccountStore } from '@@/pinia/app_bar_account'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useLibraryPlansStore } from '@@/pinia/library_plans'
import { PadletSelectionIntent } from '@@/pinia/padlet_picker'
import { usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceActivityPanelStore } from '@@/pinia/surface_activity_panel'
import { useSurfaceAnalyticsPanelStore } from '@@/pinia/surface_analytics_panel_store'
import { useSurfaceAutomationsStore } from '@@/pinia/surface_automations_store'
import { useCommentEditMode, useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceDetailsPanelStore } from '@@/pinia/surface_details_panel'
import { useSurfaceFreezePadletPanel } from '@@/pinia/surface_freeze_padlet_panel'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceRemakePanelStore } from '@@/pinia/surface_remake_panel'
import { useSurfaceRequireNoticeStore } from '@@/pinia/surface_require_notice_store'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceSharePanelStore } from '@@/pinia/surface_share_panel'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { Id } from '@@/types'
import GlobalAlertDialog from '@@/vuecomponents/GlobalAlertDialog.vue'
import GlobalConfirmationDialog from '@@/vuecomponents/GlobalConfirmationDialog.vue'
import GlobalInputDialog from '@@/vuecomponents/GlobalInputDialog.vue'
import usePostDuplicateAndTransfer from '@@/vuecomposables/post_duplicate_and_transfer'
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'

// IMPORTANT: If you're adding a new panel here, please also add it to the
// `isOverlayVisible` getter in `app/javascript/vuexstore/surface/index.ts`
const SurfaceContributors = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContributors.vue'))
const SurfaceSearchBar = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceSearchBar.vue'))
const SurfaceDetailsPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceDetailsPanel.vue'))
const SurfaceAnalyticsPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceAnalyticsPanel.vue'))
const SurfaceSettingsPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceSettingsPanel.vue'))
const SurfaceSharePanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceSharePanel.vue'))
const SurfacePanelRemake = defineAsyncComponent(() => import('@@/vuecomponents/surface_panel_remake.vue'))
const SurfaceRemakePanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceRemakePanel.vue'))
const SurfacePanelPostExpanded = defineAsyncComponent(() => import('@@/vuecomponents/SurfacePanelPostExpanded.vue'))
const SurfacePanelPostActionMenuNew = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfacePanelPostActionMenu.vue'),
)
const SurfaceSectionActionMenu = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceSectionActionMenu.vue'))
const SurfaceCommentActionMenu = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceCommentActionMenu.vue'))
const SurfaceComposerModalList = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceComposerModalList.vue'))
const UpgradeRequired = defineAsyncComponent(() => import('@@/vuecomponents/upgrade_required.vue'))
const SurfaceDropdownReactionStar = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceDropdownReactionStar.vue'),
)
const SurfaceDropdownReactionGrade = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceDropdownReactionGrade.vue'),
)
const CanvasConnectionSnackbar = defineAsyncComponent(() => import('@@/vuecomponents/CanvasConnectionSnackbar.vue'))
const DialogPermitNotification = defineAsyncComponent(() => import('@@/vuecomponents/DialogPermitNotification.vue'))
const SurfaceDialogExtensionRequired = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceDialogExtensionRequired.vue'),
)
const ReloadNotification = defineAsyncComponent(() => import('@@/vuecomponents/ReloadNotification.vue'))
const SurfaceSectionRenameModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceSectionRenameModal.vue'))
const SurfaceCommentEditDrawer = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceCommentEditDrawer.vue'))
const MembershipTierUpgradeModal = defineAsyncComponent(() => import('@@/vuecomponents/MembershipTierUpgradeModal.vue'))
const SurfaceEditBookmarkModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceEditBookmarkModal.vue'))
const MembershipLibraryTierUpgradeModal = defineAsyncComponent(
  () => import('@@/vuecomponents/MembershipLibraryTierUpgradeModal.vue'),
)
const UserMenu = defineAsyncComponent(() => import('@@/vuecomponents/UserMenu.vue'))
const SurfaceGuestIdCard = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceGuestIdCard.vue'))
const SurfaceActivityPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceActivityPanel.vue'))
const SurfaceReportPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceReportPanel.vue'))
const SurfaceDevelopersPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceDevelopersPanel.vue'))
const SurfaceFreezePadletPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceFreezePadletPanel.vue'))
const SurfaceQrCodeModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceQrCodeModal.vue'))
const SurfaceQrCodeDialog = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceQrCodeDialog.vue'))
const SurfaceAutomationsPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceAutomationsPanel.vue'))
const SurfacePanelCopyOrTransferPost = defineAsyncComponent(
  () => import('@@/vuecomponents/surface_panel_copy_or_transfer_post.vue'),
)
const SurfacePadletPickerV2 = defineAsyncComponent(() => import('@@/vuecomponents/SurfacePadletPickerV2.vue'))

withDefaults(
  defineProps<{
    reactionIdsByPostId: Record<Id, Id[]>
    commentIdsByPostId: Record<Id, Id[]>
  }>(),
  {
    reactionIdsByPostId: () => ({}),
    commentIdsByPostId: () => ({}),
  },
)

const { shouldEditCommentsInModal } = useCommentEditMode()

const isApp = device.app

const surfaceStore = useSurfaceStore()
const {
  xWallQuotaExceeded,
  fileThatExceededSize,
  xRemakePanelOld,
  isNativeMenu,
  xPermitNotification,
  xReloadNotification,
  isFirstView,
  xSearchBar,
  user,
  isMobileLayout,
  xNewComposerModalPanel,
  canUseSections,
  xReportPanel,
  xDevelopersPanel,
  xSettingsPanel,
  hasSidePanelOutsideSurface,
  xEditBookmarksDialog,
} = storeToRefs(surfaceStore)
const { xContributorsList } = storeToRefs(useSurfaceUserContributorsStore())
const { postUnderActionCid, xTransferPostPanel, postBeingTransferredCid, xCopyPostPanel, postBeingCopiedCid } =
  storeToRefs(useSurfacePostActionStore())
const { xExpandedPostPanel } = storeToRefs(useExpandedPostStore())
const { xUserMenu } = storeToRefs(useAppBarAccountStore())
const { xGuestIdCard } = storeToRefs(useSurfaceGuestStore())
const { xSurfaceSharePanel, xQrModal } = storeToRefs(useSurfaceSharePanelStore())
const { xRemakePanel } = storeToRefs(useSurfaceRemakePanelStore())
const { xUpgradeModal: xLibraryUpgradeModal } = storeToRefs(useLibraryPlansStore())
const { xUpgradeModal } = storeToRefs(usePersonalPlansStore())
const { commentUnderActionId } = storeToRefs(useCommentsStore())
const { sectionUnderActionId, xSectionRenameModal } = storeToRefs(useSurfaceSectionsStore())
const { xConnectionCancellation, xDisconnectionCancellation } = storeToRefs(useSurfacePostConnectionStore())
const { isSmallerThanTabletPortrait } = storeToRefs(useWindowSizeStore())
const { xActivityPanel } = storeToRefs(useSurfaceActivityPanelStore())
const { isContainerSmallerThanTabletLandscape } = storeToRefs(useSurfaceContainerSizeStore())
const { xSurfaceFreezePanel } = storeToRefs(useSurfaceFreezePadletPanel())
const { xSurfaceDetailsPanel } = storeToRefs(useSurfaceDetailsPanelStore())
const { xSurfaceAnalyticsPanel } = storeToRefs(useSurfaceAnalyticsPanelStore())
const { xSurfaceAutomationsPanel } = storeToRefs(useSurfaceAutomationsStore())
const { originalReactionData, postIdBeingReactedTo } = storeToRefs(useReactionsStore())
const { amIRegistered } = storeToRefs(useSurfacePermissionsStore())

const { demoPadletPanelVisibility, isDemoPadletPanelDesktop } = storeToRefs(useSurfaceOnboardingDemoPadletPanelStore())

const surfaceGuestStore = useSurfaceGuestStore()

const xCurtain = computed(() => {
  if (hasSidePanelOutsideSurface.value && isSmallerThanTabletPortrait.value) return false
  return xSurfaceSharePanel.value || xCopyPostPanel.value || xTransferPostPanel.value
})

const xCommentMenu = computed(() => {
  return commentUnderActionId.value && !isNativeMenu.value
})

onMounted(() => {
  if (isAppUsing('onboardingPanel')) {
    return
  }
  if (isFirstView.value) {
    showSettingsPanel()
  }
})

const surfaceRequireNoticeStore = useSurfaceRequireNoticeStore()
const { xExtensionRequired } = storeToRefs(surfaceRequireNoticeStore)
const { hideExtensionRequired } = surfaceRequireNoticeStore

const { hideWallQuotaExceeded, hidePermitNotification, hideReloadNotification } = surfaceStore
const { endPostTransfer, postTransferNavigate, endPostCopy, postCopyNavigate } = useSurfacePostActionStore()
const { closeUpgradeModal: closeLibraryUpgradeModal } = useLibraryPlansStore()
const { showSettingsPanel } = useSurfaceSettingsStore()

function closePlanUpgradeModal() {
  usePersonalPlansStore().closePlanUpgradeModal()
}

function navigateCopyPanel(panel) {
  postCopyNavigate({ panel })
}

function navigateTransferPanel(panel) {
  postTransferNavigate({ panel })
}

function hideCanvasConnection() {
  if (xConnectionCancellation.value) {
    useSurfacePostConnectionStore().cancelConnection()
  } else if (xDisconnectionCancellation.value) {
    useSurfacePostConnectionStore().cancelDisconnection()
  }
}

const { duplicatePost, transferPost, isCopyingPost, isTransferringPost } = usePostDuplicateAndTransfer()
</script>

<template>
  <div id="surface-panels" class="surface-panels">
    <div v-if="xCurtain" class="curtain is-animated fade-in" />
    <UserMenu
      v-if="xUserMenu"
      :user="user"
      :anchor-left-offset="isMobileLayout ? 22 : 16"
      :anchor-top-offset="isMobileLayout ? 4 : 16"
      :is-used-on-surface="true"
    />
    <SurfaceGuestIdCard
      v-if="
        surfaceGuestStore.shouldEnableAnonymousAttribution &&
        !amIRegistered &&
        xGuestIdCard &&
        !isSmallerThanTabletPortrait
      "
    />
    <SurfaceContributors v-if="xContributorsList" />
    <SurfaceSearchBar
      v-if="
        !isApp && isContainerSmallerThanTabletLandscape && xSearchBar && demoPadletPanelVisibility.showSurfaceSearchBar
      "
      :class="[
        'fixed start-0 end-0',
        hasSidePanelOutsideSurface && isSmallerThanTabletPortrait ? '-bottom-1' : 'bottom-0',
      ]"
      :should-animate-on-appear="true"
    />
    <SurfaceSettingsPanel v-if="xSettingsPanel" />
    <SurfaceSharePanel v-if="xSurfaceSharePanel" />
    <SurfaceQrCodeDialog v-if="xQrModal && isAppUsing('surfaceQrCodeV2')" @close="xQrModal = false" />
    <SurfaceQrCodeModal v-else-if="xQrModal" />
    <surface-panel-remake v-if="xRemakePanelOld && !isAppUsing('remakePanelV2')" />
    <SurfaceRemakePanel v-if="xRemakePanel && isAppUsing('remakePanelV2')" />
    <SurfacePanelPostExpanded
      v-if="xExpandedPostPanel && !isDemoPadletPanelDesktop"
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
    />
    <surface-panel-post-action-menu-new
      v-if="postUnderActionCid && !isNativeMenu && !isDemoPadletPanelDesktop"
      :post-cid="postUnderActionCid"
    />
    <SurfaceCommentActionMenu
      v-if="xCommentMenu"
      :comment-id="commentUnderActionId"
      :usage-context="xExpandedPostPanel ? 'postExpandedView' : 'postView'"
    />
    <SurfacePadletPickerV2
      v-if="isAppUsing('padletPickerV2') && isTransferringPost"
      :padlet-selection-intent="PadletSelectionIntent.TransferPost"
      :post-cid="postBeingTransferredCid"
      @select="transferPost"
      @close="endPostTransfer"
    />
    <SurfacePanelCopyOrTransferPost
      v-else-if="!isAppUsing('padletPickerV2') && xTransferPostPanel"
      :post-cid="postBeingTransferredCid"
      action="transfer"
      @navigate="navigateTransferPanel"
      @close="endPostTransfer"
    />
    <SurfacePadletPickerV2
      v-if="isAppUsing('padletPickerV2') && isCopyingPost"
      :padlet-selection-intent="PadletSelectionIntent.CopyPost"
      :post-cid="postBeingCopiedCid"
      @select="duplicatePost"
      @close="endPostCopy"
    />
    <SurfacePanelCopyOrTransferPost
      v-else-if="!isAppUsing('padletPickerV2') && xCopyPostPanel"
      :post-cid="postBeingCopiedCid"
      action="copy"
      @navigate="navigateCopyPanel"
      @close="endPostCopy"
    />
    <SurfaceComposerModalList v-if="xNewComposerModalPanel" />
    <!-- Aug 2021: replaced upgrade-required with `MembershipTierUpgradeModal` however not removed because potentially still used by very old app versions. -->
    <upgrade-required v-if="xWallQuotaExceeded" reason="walls" @close="hideWallQuotaExceeded" />

    <SurfaceCommentEditDrawer v-if="shouldEditCommentsInModal" />

    <!-- Any content pickers in surface should be rendered inside this div -->
    <!-- NOTE: Place modals that should appear beneath the content picker above this div. Place modals that should appear over the content picker below this div. -->
    <!-- Explanation: Since all modals have the same z-index, we control what gets rendered above the content picker and what gets rendered below the content picker by their relative order in the DOM -->
    <div id="content-picker-portal-target"></div>

    <MembershipTierUpgradeModal
      v-if="xUpgradeModal"
      :user="user"
      :file-that-exceeded-size="fileThatExceededSize"
      @cancel="closePlanUpgradeModal"
    />
    <MembershipLibraryTierUpgradeModal
      v-if="xLibraryUpgradeModal"
      :file-that-exceeded-size="fileThatExceededSize"
      @cancel="closeLibraryUpgradeModal"
    />
    <SurfaceDropdownReactionGrade
      v-if="postIdBeingReactedTo && originalReactionData.type == 'grade'"
      :dir="dir()"
      :usage-context="xExpandedPostPanel ? 'postExpandedView' : 'postView'"
    />
    <SurfaceDropdownReactionStar
      v-if="postIdBeingReactedTo && originalReactionData.type == 'star'"
      :dir="dir()"
      :usage-context="xExpandedPostPanel ? 'postExpandedView' : 'postView'"
    />

    <SurfaceDialogExtensionRequired v-if="xExtensionRequired" @close="hideExtensionRequired" />
    <CanvasConnectionSnackbar
      v-if="xConnectionCancellation || xDisconnectionCancellation"
      :x-connection-cancellation="xConnectionCancellation"
      :x-disconnection-cancellation="xDisconnectionCancellation"
      @close="hideCanvasConnection"
    />
    <DialogPermitNotification
      v-if="xPermitNotification && demoPadletPanelVisibility.showDialogPermitNotification"
      @close="hidePermitNotification"
    />
    <ReloadNotification v-if="xReloadNotification" @cancel="hideReloadNotification" />
    <SurfaceEditBookmarkModal v-if="xEditBookmarksDialog" />
    <GlobalAlertDialog />
    <GlobalInputDialog />
    <GlobalConfirmationDialog />
    <SurfaceSectionRenameModal v-if="xSectionRenameModal && canUseSections" />
    <SurfaceActivityPanel v-if="xActivityPanel" />
    <SurfaceSectionActionMenu v-if="sectionUnderActionId" />
    <SurfaceReportPanel v-if="xReportPanel" />
    <SurfaceDevelopersPanel v-if="xDevelopersPanel" />
    <SurfaceFreezePadletPanel v-if="xSurfaceFreezePanel" />
    <SurfaceDetailsPanel v-if="xSurfaceDetailsPanel" />
    <SurfaceAnalyticsPanel v-if="xSurfaceAnalyticsPanel" />
    <SurfaceAutomationsPanel v-if="xSurfaceAutomationsPanel" />
  </div>
</template>

<style lang="scss" scoped>
@import '@@/styles/3/modules/all';
.surface-panels {
  .curtain {
    position: fixed;
    top: 0;
    left: 0;
    width: surfaceContainerWidth;
    @include vh100;
    z-index: $sidepanel-zindex - 1;
    background-color: $translucent-backdrop-color;
    -webkit-overflow-scrolling: touch;
    touch-action: manipulation;
    @include respondToTouch {
      cursor: pointer;
    }
  }
}
</style>
